.dashboard-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboard-container {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  margin-left: -15px;
  margin-right: -15px;
  border-radius: 0px 0px 5px 5px;
  color: white;

  .stats {
    width: 80%;
    display: flex;
    flex-direction: column;

    .cards {
      display: flex;

      .card-container {
        width: 100%;
        padding-right: 0.75rem;

        &:last-child() {
          padding-right: 0;
          padding-left: 0.75rem;
        }

        .dashboard-card {
          display: flex;
          justify-content: space-between;
          height: 100px;
          width: 100%;
          border-radius: 5px;
          padding: 15px 20px;
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
          background-color: $base-light-dark;
          font-size: 15px;
          font-weight: 500;

          &.active {
            background-color: $badge-active;
          }

          &.inactive {
            background-color: $badge-rejected;
          }

          .data {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            label {
              margin: 0;
              font-weight: 500;
            }

            span {
              font-size: 32px;
              font-weight: bold;
              align-self: flex-start;
              line-height: 26px;
            }
          }

          span {
            align-self: flex-end;
            line-height: 14px;
          }
        }
      }
    }

    .activities {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background-color: $base-light-dark;
      border-radius: 5px;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      width: 100%;

      .header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        padding: 0px 10px;
        margin-bottom: 20px;
      }

      .activities-table {
        overflow-y: auto;
        flex-grow: 1;
        flex-basis: 0;

        .activity-row {
          font-size: 11px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 34px;
          padding: 0px 10px;
          text-transform: uppercase;

          &:nth-child(odd) {
            background-color: $base-dark;
          }

          .hour {
            font-weight: bold;
          }
        }
      }
    }
  }
}
