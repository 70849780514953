.indication-labels {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 161px;
    white-space: nowrap;
  
    p {
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      border-bottom: 1px solid $base-light-dark;
      margin: 0px;
      padding: 5px 0px 5px 0px;
      line-height: 20px;
  
      &.bottom-label {
        margin-top: 40px;
      }
  
      &:last-child {
        border-bottom: none;
        margin-top: 24px;
        font-size: 14px;
      }
    }
  }
  
  .indication-content {
    width: calc(100vw - 402px);
  }
  
  .indication-card {
    min-width: 190px;
    height: 710px;
    margin-right: 12px;
    text-align: center;
  
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      line-height: 31px;
    }
  
    .top {
      background-color: white;
      border-radius: 10px 10px 0px 0px;
      padding: 20px 15px;
  
      p {
        color: $base-light-dark;
  
        &:last-child {
          margin-bottom: 0px
        }
      }
  
      .title {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: normal;
        line-height: 18px;
      }
  
      .updated-price {
        height: 52px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
  
        div {
          margin: 0px;
          
          &.price-currency {
            align-self: flex-start;
            line-height: 43px;
          }
  
          &.price-unit {
            align-self: flex-end;
            line-height: 43px;
          }
  
          &.price {
            font-size: 28px;
            align-self: center;
          }
        }
      }
    }
    .bottom {
      background-color: $base-light-dark;
      border-radius: 0px 0px 10px 10px;
      padding: 20px 15px;
      color: white;
      position: relative;
  
      & p:last-child {
        margin-bottom: 0px
      }
  
      .original-price {
        font-size: 16px;
        font-weight: bold;
        margin-top: 26px;
  
        .edit {
          position: absolute;
          font-size: 19px;
          margin-left: 5px;
          margin-top: 4px;
        }
      }
    }
  
    &:hover .bottom {
      background-color: $primary;
    }
  }