.offer-confirmation-modal {
  .offer-summary {
    color: white;

    div {
      border-bottom: 1px solid white;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
    }
  }
}
