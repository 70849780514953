@import "./Indications/indications";

.form-label {
    font-size: 16px;
    color: white;
}

.reward-value {
    color: $badge-active !important;
    font-size: 30px;
    font-weight: bold;
}

.refresh-icon {
    color: white;
    font-size: 22px;
  }

.reward-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px 15px 0px;
    border-bottom: 1px solid rgba(black, 0.1);

    &:nth-last-child(2) {
        border-bottom: none;
        padding: 0px;
    }

    label {
        margin-bottom: 0;
    }

    span {
        color: white;
        
    }
}

.reward-progress {
    margin: -1.5rem !important;
    margin-top: 0px !important;
    border-radius: 0px 0px 5px 5px !important;
    height: 0.5rem !important;
    background-color: transparent !important;
    box-shadow: none !important;

    .reward-progress-bar {
        background-color: $primary;
        transition: width .2s linear !important;
    }
}

label {
    color: white;
}

.updated-value {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.port-terminal-row {
    min-height: 62.19px;
}

// -------

.prices-detail-modal {

    .price-details {
        color: white;
        font-size: 14px;
    
    
        .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            font-weight: bold;
            margin-bottom: 15px;
    
            .title {
                font-size: 20px;
            }
        }

        .price-detail-table {
            width: 400px;

            .price-detail-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 34px;
                padding: 10px;

                &:nth-child(odd) {
                    background-color: $base-dark;
                }

                .label {
                    font-size: 11px;
                    font-weight: lighter;
                    text-transform: uppercase;
                }

                .value {
                    font-weight: bold;
                }
            }
        }
    }

}
