.create-offer-client {
  .form-section {
    display: flex;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
    padding: 16px;
    border-radius: 0px 0px 5px 5px;
    background-color: $base-light-dark;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

    .form-row {
      flex-direction: column;

      .input-row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: 24px;

        span {
          color: white;
          font-size: 16px;
        }

        .css-2b097c-container,
        .css-14jk2my-container {
          width: 200px;
          margin-right: 8px;
        }

        .checkbox-input {
          margin: 15px 0;
        }

        .radio-input {
          input[type="radio"] {
            display: none;

            &:checked {
              color: hotpink;
              + .label-container {
                border-color: $primary;

                i {
                  display: block;
                  color: $primary;
                }

                label {
                  color: $primary;
                }
              }
            }
          }

          .container {
            line-height: 16px;
            letter-spacing: 0.05rem;
            font-size: 14px;
            margin-bottom: 16px;

            @media only screen and (min-width: 768px) {
              margin-bottom: 0;
            }
          }

          .label-container {
            padding: 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: 1px solid #cdcece;
            border-radius: 4px;
            min-width: 133px;
            min-height: 40px;
            text-align: center;

            i {
              margin-right: 8px;
              display: none;
            }

            label {
              line-height: 16px;
              letter-spacing: 0.05rem;
              font-size: 14px;
              margin-bottom: 0;
            }

            @media only screen and (min-width: 768px) {
              margin-right: 10px;
            }
          }
        }

        .form-control.form-input,
        .inova-input {
          width: 100%;
          height: 40px;
          color: #585c5c;
          border: none;
          border-radius: 4px;
          padding: 11px 16px;

          &:disabled {
            color: rgba(255, 255, 255, 0.5);
          }

          &:focus {
            outline-color: $primary;
          }

          + span {
            margin-left: 8px;
          }

          @media only screen and (min-width: 768px) {
            width: 200px;
          }
        }

        &.z-index-select {
          .select-input {
            &-container {
              position: relative;
              box-sizing: border-box;
            }

            &-form-control {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: space-between;
              -webkit-box-align: center;
              -webkit-box-pack: justify;
              flex-wrap: wrap;
              font-size: 14px;
              margin-bottom: 10px;
              background-color: rgb(88, 92, 92);
              border-radius: 4px;
              border: none;
              outline: 0px;
              box-sizing: border-box;
              cursor: pointer;
              transition: all 100ms ease 0s;
              min-height: 38px;
              min-width: 200px;
              height: 40px;

              .arrowContainer {
                -webkit-box-align: center;
                align-items: center;
                align-self: stretch;
                display: flex;
                flex-shrink: 0;
                box-sizing: border-box;

                .separator {
                  align-self: stretch;
                  background-color: rgb(204, 204, 204);
                  margin-bottom: 8px;
                  margin-top: 8px;
                  width: 1px;
                  box-sizing: border-box;
                }

                .svg-container {
                  color: rgb(204, 204, 204);
                  display: flex;
                  padding: 8px;
                  transition: color 150ms ease 0s;
                  box-sizing: border-box;

                  svg {
                    fill: currentcolor;
                  }
                }
              }

              @media only screen and (min-width: 768px) {
                width: 200px;
                margin-right: 10px;
              }
            }

            &-label-input-container {
              -webkit-box-align: center;
              align-items: center;
              display: flex;
              flex: 1 1 0%;
              flex-wrap: wrap;
              padding: 2px 8px;
              position: relative;
              overflow: hidden;
              box-sizing: border-box;

              .input-placeholder {
                color: white;
                margin-left: 2px;
                margin-right: 2px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                box-sizing: border-box;
              }

              .input-container {
                margin: 2px;
                padding-bottom: 2px;
                padding-top: 2px;
                visibility: visible;
                color: white;
                box-sizing: border-box;

                input {
                  box-sizing: content-box;
                  width: 2px;
                  background: 0px center;
                  border: 0px;
                  font-size: inherit;
                  opacity: 1;
                  outline: 0px;
                  padding: 0px;
                  color: inherit;
                }
              }
            }
          }
        }

        .inova-circle-btn {
          border: 1px solid $primary;
          border-radius: 100%;
          width: 40px;
          background-color: $primary;
          height: 40px;

          &:hover {
            cursor: pointer;
          }
        }

        @media only screen and (min-width: 768px) {
          flex-direction: row;
          align-items: center;

          .css-2b097c-container {
            margin-bottom: 0;
          }

          .date-picker {
            width: 183px;
          }
        }
      }

      .label-row {
        display: flex;
        align-items: center;

        i {
          margin-right: 10px;
        }

        label {
          line-height: 16px;
          letter-spacing: 0.05rem;
          font-size: 14px;
          margin: 16px 0;
        }
      }

      &.offer-expiring-date {
        .time-increment-row {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .form-control.form-input,
          .inova-input {
            margin-bottom: 10px;
          }

          @media only screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: flex-start;

            .form-control.form-input,
            .inova-input {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }
      }

      @media only screen and (min-width: 768px) {
        margin-top: 15px;
      }
    }
  }
}
