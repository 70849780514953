.closed-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: $base-light-dark;
    border-radius: 5px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

    color: white;
    font-size: 20px;

    .title {
        text-transform: uppercase;
        font-weight: bold;
        color: $primary;
        margin: 40px 0 30px 0;
    }
}