.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
}
  
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-container {
  max-width: fit-content;
  min-height: 200px;
  min-width: 200px;
  z-index: 100;
  background: $base-light-dark;
  position: relative;
  margin: 11.2rem auto;
  border-radius: 3px;
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  margin-right: -5px;
  border: none !important;
  padding: 0px !important;
}

.modal-close-button {
  padding: 0;
  background: none;
  margin: -1rem -1rem -1rem auto;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
  color: white;
  cursor: pointer;
  border: none;
  outline: 0 !important;
}

.modal-content-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.modal-message-container {
  padding: 30px;

  .message {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
  }
}