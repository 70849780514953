.radio-input {
  /* Customize the label (the container) */
  .container {
    display: block;
    position: relative;
    padding-left: 24px;
    padding-top: 2px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $base-light-dark;
    border-radius: 50%;
    border: solid white 2px;
  }

  /* On mouse-over */
  //   .container:hover input ~ .checkmark {
  //     background-color: #bc3434;
  //   }

  /* When the radio button is checked */
  .container input:checked ~ .checkmark {
    border-color: $primary;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $primary;
  }

  .tooltip {
    font-size: 12px;
    padding-left: 30px;
    opacity: 0.6;
    position: relative;
    top: -10px;
  }
}
