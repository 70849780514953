.market-hours-container {
    span {
        color: white;
        align-self: center;
        text-align: center;
        width: 50px;
    }
}

.no-market-message {
    font-weight: bold;
    color: white;
    text-align: center;
}