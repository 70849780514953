// Dark Theme
//#46525E e #2C3E4F
// old: #30363a e 3a4149
$base-dark: #3a4149;
$base-light-dark: #46525e;
$base-lighter-dark: #9d9d9d;
$base-gray: #e2e2e2;

$primary: #f5851f;
$primary75: rgba($primary, 0.75);
$primary50: rgba($primary, 0.5);
$primary25: rgba($primary, 0.25);

$sidebar-nav-link-hover-bg: $primary !important;

$font-size-base: 0.9rem;

$spacer: 1.8rem;

$badge-active: #28c76f;
$badge-inactive: $base-lighter-dark;
$badge-new: #e6b61e;
$badge-rejected: #ed5f5f;

$soy: #28c76f;
$wheat: #ffbe43;
$corn: #ffeb00;

$box-shadow: rgba(0, 0, 0, 0.2);

$sidebar-width: 165px;
