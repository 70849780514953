.date-picker {
  .form-control {
    border-radius: 0px 5px 5px 0;
    box-shadow: none;
  }
  .input-group-text {
    border-radius: 5px 0 0 5px;
    background-color: $primary;
    border-color: $primary;
    font-size: 18px;
  }
  input {
    height: 38px;
    border: none;
  }
  input:focus {
    box-shadow: 0 0 0 0.2rem $primary25 !important;
  }
  :focus>div{
    z-index: 15;
  }
  .react-datepicker-wrapper {
    width: 100% !important;
    display: grid !important;
  }
}

.date-picker:focus {
  box-shadow: 0 0 0 0.2rem $primary25 !important;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected{
  background-color: $primary !important;
  font-weight: bold;
}
