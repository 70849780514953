.offers-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.offers-container {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    background-color: $base-light-dark;
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    min-height: 0;

    .list {
        width: 70%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .detail {
        width: 30%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid $base-dark;
        font-size: 12px;
        color: white;

        .inova-btn {
            min-width: unset !important;
        }

        .header {
            padding: 15px 20px;
            width: 100%;
            box-shadow: 0px 1px 0px 0px $base-dark;

            .title {
                font-size: 12px;
                color: $base-gray;
                text-transform: uppercase;
            }

            .info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .name {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }

        .body {
            padding: 15px 20px;
            flex-grow: 1;
            flex-basis: 0;
            overflow-y: auto;

            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-transform: uppercase;
                height: 40px;
                box-shadow: 0px 1px 0px 0px $base-dark;

                label {
                    font-size: 11px;
                    font-weight: normal;
                    margin: 0px;
                }

                p {
                    font-weight: bold;
                    margin: 0px;
                }

                .counter-offer-original {
                    text-decoration: line-through;
                }

                .counter-offer-new {
                    color: $badge-active;
                    margin-left: 10px;
                }
            }

        }

        .buttons {
            padding: 15px 20px;
            padding-top: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}

.offer-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: $badge-active;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.expired {
        color: #ED5F5F;
    }

    .time {
        display: flex;
        align-items: center;
        width: 75px;
        margin: 0 !important;
    }
}

.offer-card {
    display: flex;
    width: 100%;
    min-height: 80px;

    border-right: 3px solid transparent;
    box-shadow: 0px 1px 0px 0px $base-dark;
        
    &.highlighted {
        background-color: #30363A;

        .updated-values {
            background-color: rgba(black, 0.3);
        }
    }

    &.selected {
        border-right: 3px solid $badge-active;
        background-color: #1C1F21;

        .updated-values {
            background-color: rgba(black, 0.3);
        }
    }
    
    .updated-values {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 250px;
        padding: 15px 20px;
        background-color: rgba(black, 0.1);

        div {
            display: flex;
            font-size: 11px;
            justify-content: space-between;
            margin-bottom: 2px;
        }

        .value-label {
            text-transform: uppercase;
            color: $base-lighter-dark;
            margin-bottom: 0px;
        }
        
        .value {
            color: $base-gray;
            margin-bottom: 0px;
        }
    }

    .offer-values {
        display: flex;
        flex-grow: 1;
        color: white;
        align-items: center;
        font-size: 12px;
        padding: 15px 0;
        
        p {
            margin-bottom: 3px;
        }

        .offer-info {
            display: flex;
            flex-grow: 1;

            .offer-col-1 {
                width: 40%;
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
    
            .offer-col-2 {
                width: 20%;
                padding-left: 15px;
                justify-content: center;
                display: flex;
                flex-direction: column;
            }
    
            .offer-col-3 {
                width: 40%; 
                padding-left: 15px;
                justify-content: center;
                display: flex;
                flex-direction: column;
            }
        }


        .offer-state {
            width: 95px;
            display: flex;
            margin-left: auto;
            justify-content: center;
            padding-left: 20px;
            padding-right: 20px;

            .approved-info {
                color: $badge-active;
            }

            .approved-time {
                font-size: 14px;
                font-weight: bold;
            }
        }

        .price {
            font-size: 14px;
            font-weight: bold;
        }

    }
}

.offer-alteration-container {
    width: 420px;

    .offer-alteration-fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    
        p {
            margin: 0;
            font-size: 12px;
            color: white;
            text-transform: uppercase;
        }
    
        .offer-input {
            width: 128px;
        }
    }
}

.no-offer-message {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    color: white;
}

.business-confirmation-modal {
    background-color: $base-dark !important;

    .business-confirmation-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 14px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0 -15px;
        margin-bottom: 10px;

        .title {
            font-size: 20px;
            text-transform: none;
        }
    }

    .modal-section {
        background-color: $base-light-dark !important;
        border-radius: 5px;
        text-align: left;
        padding: 20px;
        font-size: 14px;
        color: white;
        width: 80vw;
        margin-bottom: 20px;
        

        .title {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .field-row {
            display: flex;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0px;
            }

            div {
                display: flex;
                flex-direction: column;
            }
        }

        label {
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        .text-input {
            min-height: 110px !important;
            resize: none;
          }

    }
}