.ic-pack::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-pack.svg") no-repeat;
}

.ic-weight::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-weight.svg") no-repeat;
}

.ic-archive::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-archive.svg") no-repeat;
}

.ic-map-pin::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-map-pin.svg") no-repeat;
}

.ic-port::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-port.svg") no-repeat;
}

.ic-calendar::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-calendar.svg") no-repeat;
}

.ic-coin::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-coin.svg") no-repeat;
}

.ic-dashboard::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-dashboard.svg") no-repeat;
}

.ic-dolar::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-dolar.svg") no-repeat;
}

.ic-frete::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-frete.svg") no-repeat;
}

.ic-historico::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-historico.svg") no-repeat;
}

.ic-ofertas-ativas::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-ofertas-ativas.svg") no-repeat;
}

.ic-ofertas-consolidadas::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-ofertas-consolidadas.svg")
    no-repeat;
}

.ic-parametros::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-parametros.svg") no-repeat;
}

.ic-user::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-user.svg") no-repeat;
}

.ic-leaf::after {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent url("../Assets/Icons/ic-leaf.svg") no-repeat;
}

.ic-cbot::after {
  display: flex;
  content: "";
  width: 18px;
  height: 20px;
  background: transparent url("../Assets/Icons/ic-cbot.svg") no-repeat;
}

.ic-indications::after {
  display: flex;
  content: "";
  width: 18px;
  height: 20px;
  background: transparent url("../Assets/Icons/ic-indications.svg") no-repeat;
}

.ic-payment::after {
  display: flex;
  content: "";
  width: 16px;
  height: 16px;
  background: transparent url("../Assets/Icons/ic-payment.svg") no-repeat;
}
