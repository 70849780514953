.observation-input {
  min-height: 122px !important;
  resize: none;
}

.back-button {
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.divider {
  width: 1px;
  border-radius: 10px;
  background-color: $base-lighter-dark;
}

.inova-details {
  label {
    display: block;
    margin-bottom: 0.25rem;
  }

  span {
    color: white;
    font-size: 16px;
  }

  h4 {
    margin-bottom: 26px;
  }

  ul {
    color: white;
  }
}
