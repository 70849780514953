.rc-switch {
    border: 1px solid $badge-rejected !important;
    background-color: $badge-rejected !important;
    width: 60px !important;
    height: 30px !important;

    &:focus {
        box-shadow: 0 0 0 0.2rem $primary25 !important;
    }

    &:after {
        width: 24px !important;
        height: 24px !important;
        left: 3px !important;
        top: 2px !important;
    }
}

.rc-switch-checked {
    border: 1px solid $badge-active !important;
    background-color: $badge-active !important;

    &:after {
        left: 32px !important;
    }
}

.switch-input-label {
    font-size: 12px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}